import React, { useEffect, useRef, useState } from "react";
import { API } from "aws-amplify";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { useScreenshot } from "use-react-screenshot";
import { QRCodeSVG } from "qrcode.react";

import {
  NormalSM,
  NormalMD,
  NormalLG,
  NormalXL,
  Medium,
  BoldMD,
  BoldLG,
  BoldXL,
  BlackMD,
  BlackLG,
  BlackXL,
} from "../components/CustomLabel";
import CustomButton from "../components/CustomButton";
import CustomGlobalLoading from "../components/CustomGlobalLoading";
import { getDigitalLicenseURL } from "../utils/Tools";
import { pdfFormat, tableRow } from "./StoryReports/pdfFormat";
import authorization_signature from "../assets/authorization_signature.png";
import logo_quintana_roo from "../assets/logo_quintana_roo.png";
import license_back_background from "../assets/license_back_background.png";
import quintana_roo from "../assets/quintana_roo.png";
import mobility_and_transportation from "../assets/mobility_and_transportation.png";
import shield_of_mexico from "../assets/shield_of_mexico.png";
import state_goverment from "../assets/state_goverment.svg";
import icon_checkmark from "../assets/icon_checkmark.png";
import icon_crossmark from "../assets/icon_crossmark.png";
import icon_warning from "../assets/icon_warning.png";

const LicenseViewer = ({ data, onClose = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);

  const entryDate = new Date(localStorage.getItem("entryDate"));
  const status = getStatus(data);
  const digitalLicenseURL = getDigitalLicenseURL(data.id);
  const photographyStyle = {
    transform: data.posicion
      ? `translate(${-data.posicion}px, 0)`
      : `translateX(calc(50% - 39.5px))`,
    ...(data.posicion ? { left: 0 } : { right: 0 }),
  };

  const licenseRef = useRef(null);
  const [image, takeScreenshot] = useScreenshot();

  async function generateImg() {
    await new Promise((resolve) => setTimeout(resolve, 100));
    takeScreenshot(licenseRef.current).then(() => {
      licenseRef.current.remove();
    });
  }

  async function printPDF() {
    setIsLoading(true);

    try {
      const params = {
        body: {
          curp: data.conductor.curp,
        },
      };

      const history = await API.post(
        "userRoles",
        "/getLicenseHistory",
        params
      ).then((response) => response.licenses);

      let licenseArray = [];
      if (history.length >= 5) {
        licenseArray.push('<div class="html2pdf__page-break"></div>');
      }
      history.forEach((license, index) => {
        const expedicion = moment(license.fechaExpedicion).format("DD/MM/YYYY");
        const vigencia = moment(license.fechaVigencia).format("DD/MM/YYYY");

        licenseArray.push(
          tableRow
            .replace("{{NUMBER}}", index + 1)
            .replace("{{NUMERO_LICENCIA}}", license.numero)
            .replace("{{TIPO_LICENCIA}}", license.tipoLicencia?.tipo)
            .replace("{{FECHA_EXPEDICION}}", expedicion)
            .replace("{{FECHA_VENCIMIENTO}}", vigencia)
        );
      });
      const date = moment().format("DD-MM-YY");
      const reportName = `Historial ${data.conductor.curp} ${date}`;
      const options = {
        filename: `${reportName}.pdf`,
        jsPDF: { format: "letter", orientation: "portrait" },
        margin: [10, 20],
        html2canvas: { scale: 2 },
      };
      const driverName = [
        data.conductor.nombre,
        data.conductor.apellidoPaterno,
        data.conductor.apellidoMaterno,
      ]
        .filter((x) => x)
        .join(" ");

      const htmlContent = pdfFormat
        .replace("{{TABLE_ROWS}}", licenseArray.join(""))
        .replace("{{USER_IMAGE}}", data.photography)
        .replaceAll("{{DIA}}", moment().format("DD"))
        .replaceAll("{{MES}}", moment().format("MMMM"))
        .replaceAll("{{ANIO}}", moment().format("yyyy"))
        .replaceAll("{{MUNICIPIO}}", history[0].municipio.nombre)
        .replaceAll("{{CONDUCTOR}}", driverName);

      html2pdf().set(options).from(htmlContent).toPdf().save();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    generateImg();
  }, []);

  return (
    <div>
      <div
        ref={licenseRef}
        className="w-[380px] h-[469px] relative flex flex-col rounded-lg border-b-[10px] border-rose-800 bg-bottom bg-no-repeat	bg-contain"
        style={{
          backgroundImage: `url(${license_back_background})`,
          position: "absolute",
          right: window.innerWidth * 2,
          transform: "scale(2)",
          transformOrigin: "0 0",
        }}
      >
        <div className="h-1/2 flex gap-5 px-4 py-5 bg-white opacity-80">
          <div className="flex flex-col gap-4">
            <div className="h-8 flex flex-col justify-end gap-1">
              <BlackMD>
                LICENCIA
                <br />
                PARA CONDUCIR
              </BlackMD>
              <NormalSM>DRIVERS LICENCE</NormalSM>
            </div>
            <div className="flex-1 flex flex-col">
              <div
                className="w-[79px] h-[96px]"
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <img
                  src={"data:image/png;base64," + data.photography}
                  alt=""
                  className="h-full absolute top-0 w-auto max-w-none"
                  style={photographyStyle}
                />
              </div>

              <div className="flex-1 flex flex-col items-center justify-between">
                <div className="flex-1 w-[79px] h-[25px] flex flex-col justify-center items-center">
                  <img
                    className="h-[inherit] w-fit"
                    src={data.signature}
                    alt=""
                  />
                </div>
                <NormalMD>FIRMA/SIGNATURE:</NormalMD>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex gap-5">
              <div className="flex-1 flex flex-col gap-4">
                <div className="h-8 flex flex-col justify-end">
                  <img
                    src={quintana_roo}
                    alt="Logo Quintana Roo"
                    width={100}
                    height={34}
                  />
                </div>
                <div className="flex flex-col gap-0.5">
                  <div className="-mt-2.5 flex items-center gap-1 justify-end h-6 translate-x-5">
                    <div
                      className={`${TAG_COLORS[status].class} font-['Montserrat'] uppercase font-black text-base`}
                    >
                      {status}
                    </div>
                    <img
                      className="translate-y-2"
                      src={TAG_COLORS[status].icon}
                      alt="Icono del estatus"
                      width={32}
                      height={32}
                    />
                  </div>
                  <div className="-mt-1">
                    <BlackXL>{data.conductor.nombre}</BlackXL>
                    <BlackXL>
                      {[
                        data.conductor.apellidoPaterno,
                        data.conductor.apellidoMaterno,
                      ]
                        .filter((x) => x)
                        .join(" ")}
                    </BlackXL>
                  </div>
                  <div>
                    <NormalXL>CURP:</NormalXL>
                    <BoldXL>{data.conductor.curp}</BoldXL>
                  </div>
                  <div>
                    <NormalXL>EXPEDICIÓN/ISSUED:</NormalXL>
                    <BoldMD>
                      {moment(data.fechaExpedicion).format("DD/MM/YYYY")}
                    </BoldMD>
                  </div>
                  <div>
                    <NormalXL>VENCIMIENTO/ EXPIRES:</NormalXL>
                    <BoldMD>
                      {moment(data.fechaVigencia).format("DD/MM/YYYY")}
                    </BoldMD>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-1.5">
                <div className="flex flex-col items-center">
                  <img
                    src={shield_of_mexico}
                    alt="Escudo de México"
                    width={38}
                    height={38}
                  />
                  <BoldLG>MÉXICO</BoldLG>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <BoldLG textCenter>
                    TIPO DE
                    <br />
                    LICENCIA
                  </BoldLG>
                  <div className="w-7 h-7 p-1 rounded-full border border-rose-800 justify-center items-center flex mt-2">
                    <div className="text-center text-stone-700 text-lg font-black font-['Montserrat'] leading-tight pb-4">
                      {data.tipoLicencia.tipo}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end gap-1">
              <div className="flex-1 flex flex-col ">
                <NormalLG>FOLIO / DOCUMENT:</NormalLG>
                <BlackXL>{data.numero}</BlackXL>
              </div>
              <div className="flex flex-col items-center">
                <BlackLG>{data.tipoLicencia.nombre}</BlackLG>
                <NormalXL textCenter>
                  INSTITUTO DE MOVILIDAD
                  <br />
                  DEL ESTADO DE QUINTANA ROO
                </NormalXL>
              </div>
            </div>
          </div>
        </div>

        <div className="h-1/2 flex gap-5 px-4 py-5 bg-white opacity-80">
          <div className="w-16 flex flex-col items-center border-r border-neutral-300">
            <img
              src={logo_quintana_roo}
              alt="Icono Quintana Roo"
              width="44"
              height="39"
            />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-col gap-2">
              <NormalXL>
                DONADOR DE ÓRGANOS Y TEJIDOS
                <br />
                ORGAN DONOR:
                <BoldXL inline>
                  {" "}
                  {data.conductor.donadorOrganos ? "SI" : "NO"}
                </BoldXL>
              </NormalXL>
              <NormalXL>
                TIPO SANGUINEO
                <br />
                BLOOD TYPE:
                <BoldXL inline> {data.conductor.grupoSanguineo || "NA"}</BoldXL>
              </NormalXL>
              <NormalXL>
                FECHA DE NACIMIENTO
                <br />
                DATE OF BIRTH:
                <BoldXL inline> {data.conductor.fechaNacimiento}</BoldXL>
              </NormalXL>
              <NormalXL>
                OBS MÉDICA
                <br />
                MEDICAL OBS:
                <BoldXL inline>
                  {" "}
                  {data.conductor.padecimientos || "SIN RESTRICCIONES"}
                </BoldXL>
              </NormalXL>
            </div>
            <div>
              <div className="flex gap-5 items-end">
                <NormalLG>AUTORIZÓ:</NormalLG>
                <img
                  className="h-[27.2px]"
                  src={authorization_signature}
                  alt=""
                />
              </div>
              <BlackMD>Rafael Hernández Kotasek</BlackMD>
              <Medium>Encargado de la Dirección General del IMOVEQROO</Medium>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4">
            <img src={state_goverment} alt="Gobierno del Estado" />
            <img
              src={mobility_and_transportation}
              alt="Movilidad y Transporte"
              width="35"
              height="33"
            />
            <QRCodeSVG value={digitalLicenseURL} size={41} />
          </div>
        </div>

        <div className="absolute bottom-[-10px] rounded-b-lg px-2 pb-2 bg-rose-800">
          <div className="font-normal text-[8.06px] leading-[8.88px] text-white font-['Montserrat']">
            Todas las autoridades deberán hacerla válida como identificación
            oficial en base a lo establecido en el Artículo 52 Fraccion IV de la
            Ley General de Movilidad y Seguridad Vial.
          </div>
        </div>
      </div>

      {image && (
        <>
          <div>
            <img
              width={window.innerWidth}
              style={{ maxWidth: 380 }}
              src={image}
              alt={"License"}
            />
          </div>
          <div className="w-full flex justify-center text-lg">
            {moment(entryDate).format("MMMM D YYYY, h:mm a")}
          </div>
          <div className="flex justify-between mt-8">
            <CustomButton className="w-44" text="Salir" onClick={onClose} />
            <CustomButton
              className="w-44"
              text="Historial"
              onClick={printPDF}
            />
          </div>
        </>
      )}

      <CustomGlobalLoading isLoading={isLoading || !image} />
    </div>
  );
};

const getStatus = (license) => {
  const dateNowTimestamp = moment().startOf("day");
  const fechaVigenciaTimestamp = moment(license.fechaVigencia);

  let status = "Vigente";

  if (license.conductor?.estatus === "Cancelado") {
    status = "Cancelada";
  } else if (
    license.conductor?.estatus === "Suspendido" &&
    moment(license.conductor.fechaSuspension).isAfter(moment())
  ) {
    status = "Suspendida";
  } else if (dateNowTimestamp > fechaVigenciaTimestamp) {
    status = "Vencida";
  }

  return status;
};

const TAG_COLORS = {
  Vigente: {
    class: "text-[#00ba00]",
    icon: icon_checkmark,
  },
  Cancelada: {
    class: "text-[#ff0000]",
    icon: icon_crossmark,
  },
  Vencida: {
    class: "text-[#ff0000]",
    icon: icon_crossmark,
  },
  Suspendida: {
    class: "text-[#ffd42a]",
    icon: icon_warning,
  },
  Infraccionada: {
    class: "text-[#ffd42a]",
    icon: icon_warning,
  },
};

export default LicenseViewer;
